<template>
  <div class="job-card" :class="{ hightlight: jobs.jobTag }">
    <a :href="'/job_detail/' + jobs._id" v-if="jobs._id" class="card-header">
      <div class="card-header-start">
        <div class="logo">
          <img :src="getUrlImage + jobs.logo" alt="" loading="lazy" />
        </div>
        <small>
          <h3>{{ jobs.companyName }}</h3>
          <p>{{ calPostAgo(jobs.openingDate) }}</p>
        </small>
      </div>
      <i class="fa-solid fa-wheelchair" v-show="jobs.disabledPeople"></i>
    </a>
    <a class="job-card-body" :href="'/job_detail/' + jobs._id" v-if="jobs._id">
      <h3 class="job-title">
        {{ jobs.title }}
      </h3>
      <ul>
        <li>
          <i class="fa-regular fa-map-location-dot"></i
          >{{ jobs.workingLocations }}
        </li>
        <li>
          <i class="fa-regular fa-calendar-days"></i>
          <span
            >{{ formateDate(jobs.openingDate) }} -
            {{ formateDate(jobs.closingDate) }}</span
          >
        </li>
      </ul>
    </a>

    <div class="job-card-footer">
      <HideJob
        :JobId="jobs._id"
        type="hide"
        @fetchData="(value: any) => emit('fetchData', value)"
      />

      <!-- save job  -->
      <div
        class="jobcard-savejob"
        @click="handleSaveJob(jobs)"
        :class="{ saved: jobs.isSaved }"
      >
        <i class="fa-regular fa-heart"></i>
        <p v-if="jobs.isSaved">{{ $t('saved') }}</p>
        <p v-else>{{ $t('save job') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formateDate, duration } from '@/services/formatDate'
import { useManageJobStore } from '@/store/manageJob'
import { useAuthStore } from '@/store/auth'
const { saveJob, isSave } = useManageJobStore()
const Auth = useAuthStore()
import SaveJob from '~/components/reuse/saveJob.vue'
import HideJob from '~/components/reuse/hideJob.vue'
import { useI18n } from 'vue-i18n'
const props = defineProps(['jobs'])
const emit = defineEmits(['setData', 'fetchData'])
const { t } = useI18n()
const getUrlImage = useNuxtApp().$urlImage
// watch
const isHighlight = computed(() => {
  return (tags: { name: string }[]) => {
    if (!tags.length) return false
    const namMapped = tags.map((i) => i.name)
    return namMapped.includes('Highlight')
  }
})

// day cal
const calPostAgo = (job: any) => {
  return duration(job)
}

//save job function
const handleSaveJob = async (job: any) => {
  try {
    if (!Auth.isAuth) {
      Auth.LoginModal(true)
    } else {
      // Access JobId and isSave directly from destructured props
      emit('setData', { _id: job._id, isSave: job.isSaved })
      await saveJob(job._id)
      Auth.LoginModal(false)
    }
  } catch (error) {}
}
</script>
<style lang="scss" scoped>
.job-card {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: var(--round-md);
  display: flex;
  flex-direction: column;
  transition: opacity 0.15s ease-in-out;
  box-shadow: var(--job-card-shadow);
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: var(--job-card-shadow-hover);
  }

  &.hightlight {
    box-shadow: 0 0 10px 1px rgba(var(--alert-color-rgb), 0.25),
      inset 0 0 0 2px rgba(var(--alert-color-rgb), 1);
    background-color: var(--light-alert-color);

    .job-title {
      color: var(--alert-color);
    }
  }

  .card-header {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 1.25rem 1.25rem 0 1.25rem;
    justify-content: space-between;

    .card-header-start {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .logo {
        border: 1px solid var(--border-color);
        position: relative;
        min-width: 60px;
        min-height: 60px;
        max-width: 60px;
        max-height: 60px;
        border-radius: 10px;
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        img {
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;
          object-fit: contain;
        }
      }

      small {
        h3 {
          font-size: var(--sm-font);
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.3;
        }

        p {
          font-size: var(--xsm-font);
          color: var(--grey-text-color);
        }
      }
    }

    i {
      background-color: var(--alert-color);
      color: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      user-select: none;
    }
  }

  .job-card-body {
    padding: 1.25rem;
    flex-grow: 1;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    position: relative;

    .job-title {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: var(--md-font);
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: var(--primary-color);
      }
    }

    ul {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      li {
        color: var(--grey-text-color);
        font-size: var(--sm-font);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;

        i {
          font-size: var(--xsm-font);
          width: 20px;
          text-align: center;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .job-card-footer {
    box-shadow: 0 -1px var(--border-color);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(1.25rem - 2px);
    padding-right: calc(1.25rem - 2px);
    margin-left: 2px;
    margin-right: 2px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .jobcard-savejob {
      display: flex;
      align-items: center;
      font-size: var(--sm-font);
      gap: 0.25rem;

      &.saved,
      &:hover {
        color: var(--primary-color);

        i {
          font-weight: 700;
        }
      }

      i {
        transition: all ease-in-out 0.15s;
      }

      p {
      }
    }
  }
}
</style>
